import React from 'react'
import { GetServerSideProps, Redirect } from 'next'
import Head from 'next/head'
import { Heading, Text } from '@chakra-ui/react'
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query'
import isMobile from 'ismobilejs'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import styles from './slug.module.css'
import { APP_VERSION } from 'composable/components/general'
import GASnippet from '../components/headless/GASnippet'
import { createClient, PageDataResponse, ResponseError } from '../frontastic'
import { FrontasticRenderer } from '../frontastic/lib/renderer'
import { tastics } from '../frontastic/tastics'
import { useFormat } from '../helpers/hooks/useFormat'
import { Log } from '../helpers_composable/errorLogger'
import { sdk } from '../sdk'

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string
}

export default function Slug({ data }: SlugProps) {
  const locale = 'en_US'
  const { formatMessage } = useFormat({ name: 'common' })

  const renderError = ({ data }: { data: SlugProps['data'] }) => (
    <>
      <Heading as="h1" size="xl" fontWeight="extrabold">
        Internal Error
      </Heading>
      <Text as="p" fontSize={{ base: 'mobile.body', md: 'desktop.body' }}>
        {data.message || data}
      </Text>
      <Text as="p" fontSize={{ base: 'mobile.body', md: 'desktop.body' }}>
        Check the logs of your Frontastic CLI for more details.
      </Text>
    </>
  )

  if (!data || typeof data === 'string') {
    return renderError({ data })
  }

  if (!data?.ok && data?.message) {
    return renderError({ data })
  }

  const isNonGenericPage = data?.pageFolder?.configuration?.pathTranslations.en_US === null
  const mainElements = data?.data?.dataSources
  const fallbackTitleFromCMSEntries = findTitleFromCMSEntries(mainElements)

  let seoTitle = data?.pageFolder?.configuration?.seoTitle || fallbackTitleFromCMSEntries || 'DXL'
  let seoDescription =
    data?.pageFolder?.configuration?.seoDescription ||
    fallbackTitleFromCMSEntries ||
    'Find largest home collections here!'
  let seoKeywords = data?.pageFolder?.configuration?.seoKeywords || fallbackTitleFromCMSEntries || ''

  if (isNonGenericPage) {
    seoTitle = 'DXL'
    seoDescription = 'Find largest home collections here!'
    seoKeywords = ''
  }

  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <title>{seoTitle}</title>
      </Head>
      <GASnippet />
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  )
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({
  params,
  locale,
  query,
  req,
  res,
  resolvedUrl,
}) => {
  sdk.configureForNext(locale as string)

  const frontastic = createClient()

  const [data, categories] = await Promise.all([
    frontastic.getRouteData(params?.slug as string[], query as AcceptedQueryTypes, req, res),
    frontastic.getCategories(req, res),
  ])

  if (data) {
    if (
      (typeof data === 'object' && Object.keys(data).length === 0) ||
      (data instanceof ResponseError && data.getStatus() == 404)
    ) {
      return {
        redirect: {
          destination: '/not-found',
          statusCode: 301,
        } as Redirect,
      }
    }
    if ((data as PageDataResponse)?.pageFolder?.isDynamic && APP_VERSION === 'R1') {
      // TEMPORARY REDIRECT OF DYNAMIC PAGES TO NOT FOUND WHILE IN R1
      return {
        redirect: {
          destination: '/not-found',
          statusCode: 302,
        } as Redirect,
      }
    }

    if (typeof data === 'object' && 'target' in data && 'statusCode' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      }
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error(new Error('Error retrieving data: '), data)
    return {
      redirect: {
        destination: '/not-found',
        statusCode: 301,
      } as Redirect,
    }
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    }
  }

  const protocol = req.headers.referer?.split('://')[0] || 'https'
  const serverUrl = `${protocol}://${req.headers.host}${resolvedUrl}`

  const userAgent = req.headers['user-agent']
  const isMobileDevice = isMobile(userAgent).any

  return {
    props: {
      data: { ...data, categories, serverUrl, isMobileDevice: !!isMobileDevice } || null,
      locale: locale,
      ...(await serverSideTranslations(locale as string, ['common'])),
    },
  }
}

const findTitleFromCMSEntries = (cmsEntries: any): string | undefined => {
  if (typeof cmsEntries === 'object') {
    for (const key in cmsEntries) {
      const cmsEntryData = cmsEntries[key]

      if (cmsEntryData.hasOwnProperty('title')) {
        return cmsEntryData.title
      }
    }
  }
  return undefined
}
